<script setup>
import Error from '@/Components/Input/Error.vue';
import Label from '@/Components/Input/Label.vue';
import theme from '@/formkitTheme';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

const props = defineProps({
    label: {},
    value: {},
    errors: [],
    disabled: Boolean,
});
const emit = defineEmits(['update:value']);

const locale = computed(() => usePage().props.locale);

const displayValue = computed(() => {
    if (locale.value == 'nl') {
        return String(props.value)
            .replace(/[^\d.-]/g, '')
            .replace('.', ',');
    }
    return props.value;
});

const emitValue = (value) => {
    value = value.target.value;

    let asString = String(value)
        .replace(',', '.')
        .replace(/[^\d.-]/g, '');

    let asFloat = parseFloat(asString);

    if (isNaN(asFloat)) {
        asFloat = 0;
    }

    emit('update:value', asFloat);
};
</script>

<template>
    <div :class="theme.global.outer">
        <Label :label="label" />
        <div>
            <input
                :id="label ?? 'input'"
                type="text"
                :class="theme.global.input"
                :value="displayValue"
                @blur="emitValue"
                @change="emitValue"
                :disabled="disabled"
            />
        </div>
        <Error :error="errors" />
    </div>
</template>
